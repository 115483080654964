<template>
  <div class="footer__wrapper">
    <div class="footer__container">
      <div :class="`${width > t_lg ? 'block block__between' : ''}`">
        <div>
          <div class="footer__top_logo">
            <I5Footer />
          </div>
          <div class="footer__bottom_info">
            <div class="footer__bottom_title component__margin-bottom">
              {{footer.rights_reserved[language]}}
            </div>
            <div class="footer__bottom_address component__margin-bottom">
              {{footer.address[language]}}
            </div>
            <div class="footer__bottom_copy">
              <span>{{footer.company[language]}} </span>
              <span>2019-{{year}}</span>
            </div>
          </div>
        </div>
        <div style="width: 100%" v-if="width > t_lg">
          <div class="footer__top_center"></div>
          <div class="footer__bottom_center"></div>
        </div>
        <div>
          <div class="footer__top_social">
            <a href="https://vk.com/i5.solutions" target="_blank">
              <VK />
            </a>
            <a
              href="https://www.youtube.com/channel/UCifBise6J5bjBfIaaD_cyFA"
              target="_blank"
              style="margin-left: 15px;">
              <Youtube />
            </a>
          </div>
          <div class="footer__bottom_politics">
            <div class="footer__bottom_files" @click="goTo('/document/personal_data')">
              {{footer.personal_data[language]}}
            </div>
            <div class="footer__bottom_files" @click="goTo('/document/confidentiality')">
              {{footer.confidentiality[language]}}
            </div>
            <div class="footer__bottom_files" @click="goTo('/document/term_of_use')">
              {{footer.term_of_use[language]}}
            </div>
            <div class="footer__bottom_files" @click="goTo('/document/offer')">
              {{footer.offer[language]}}
            </div>
            <div class="footer__bottom_files" @click="goTo('/document/cookies')">
              {{footer.cookies[language]}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import footer from '@/assets/text/footer.json';
import I5Footer from '@/assets/pictures/i5/i5Footer.svg';
import VK from '@/assets/pictures/social/vkLogo.svg';
import Youtube from '@/assets/pictures/social/youtubeIcon.svg';

export default {
  name: 'Footer',
  data() {
    return {
      footer,
      year: new Date().getFullYear(),
    };
  },
  computed: {
    t_lg() {
      return this.$store.state.index.t_lg;
    },
    width() {
      return this.$store.state.index.width;
    },
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    goTo(path) {
      this.$router.push(path);
    },
  },
  components: {
    I5Footer,
    VK,
    Youtube,
  },
};
</script>
